<!-- 等级编辑弹窗 -->
<template>
  <el-dialog
    :title="isUpdate?'修改模块比例':'模块比例'"
    :visible="visible"
    width="500px"
    :destroy-on-close="true"
    :lock-scroll="false"
    @update:visible="updateVisible">
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="82px">
      <input type="hidden" name="role_id" v-model="form.id"/>
      <el-row :gutter="18" v-for="item in form.current">
        <el-col :sm="8">
        <label for="roleName[]" class="el-form-item__label">{{ item.title }}:</label>
        </el-col>
        <el-col :sm="15">
        <el-input-number class="nums" @change="total(this)" v-model="item.prop" placeholder="请输入比例" clearable/>
        </el-col>

      </el-row>
      <el-row :gutter="15">
        <el-col :sm="8">
          <label for="roleName[]" class="el-form-item__label" style="width: 82px;">试题总数:</label>
        </el-col>
        <el-col :sm="15">
          <el-input-number
                  v-model="form.before_test_total"
                  placeholder="请输入前测题总数"
                  clearable/>
        </el-col>


      </el-row>
    </el-form>
    <div slot="footer">
      <el-button @click="updateVisible(false)">取消</el-button>
      <el-button
        type="primary"
        @click="save"
        :loading="loading">保存
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'LevelEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 表单验证规则
      rules: {
        name: [
          {required: true, message: '请输入模块比例', trigger: 'blur'}
        ],
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false
    }
  },
  watch: {
    data() {
      if(this.data.current){
        //设置后数据
        this.$http['get']('/Admin/levelModule?id='+ this.data.id).then(res => {
          this.data.current = res.data.data;
          this.form = Object.assign({}, this.data);
          //console.log(this.form)
        })
      }else{
        this.$message.error("请先分配等级下模块");
        this.loading = false;
        this.updateVisible(false);
        this.$emit('done');
      }
    }
  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$http['post']('/Admin/saveLevelModule', this.form).then(res => {
            //console.log(res)
            this.loading = false;
            if (res.data.code === 200) {
              this.$message({type: 'success', message: res.data.msg});
              if (!this.isUpdate) {
                this.form = {};
              }
              this.updateVisible(false);
              this.$emit('done');
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
          });
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },
    total() {
      var totals = 0;
      var arr = this.data.current;
      var index = arr.findIndex(item =>{
        totals += item.prop;

      })
      this.form.before_test_total = totals;
      //console.log(totals)
    }
  }
}
function total(that) {
  console.log(that.val())
}
</script>

<style scoped>
</style>
