<template>
  <div class="ele-body">
    <el-card shadow="never">
      <ele-pro-table
        ref="table"
        :needPage="false"
        :datasource="url"
        :columns="columns"
        :response="response"
        :selection.sync="selection"
      >
        <!-- 表头工具栏 -->
        <template slot="toolbar">


        </template>
        <!-- 操作列 -->
        <template slot="action" slot-scope="{ row }">
          <el-link
                  @click="openAuth(row)"
                  icon="el-icon-finished"
                  type="primary"
                  :underline="false"
          >分配比例
          </el-link>
        </template>
      </ele-pro-table>
    </el-card>
    <!-- 编辑弹窗 -->
    <!-- 权限分配弹窗 -->
    <role-auth :visible.sync="showAuth" :data="current" @done="reload"/>
  </div>
</template>

<script>
import RoleAuth from "./level-edit";

export default {
  name: "Level",
  components: { RoleAuth },
  data() {
    return {
      // 表格数据接口
      url: "/Admin/level",
      response: {
        statusCode: 200,
        dataName: "data",
      },
      // 表格列配置
      columns: [
        {
          columnKey: "selection",
          type: "selection",
          width: 45,
          align: "center",
          fixed: "left",
        },
        {
          columnKey: "index",
          type: "index",
          width: 45,
          align: "center",
          fixed: "left",
          showOverflowTooltip: true,
        },
        {
          prop: "name",
          label: "等级",
          sortable: "custom",
          showOverflowTooltip: true,
          minWidth: 110,
        },
        {
          prop: "before_test_total",
          label: "前测题总数",
          sortable: "custom",
          showOverflowTooltip: true,
          minWidth: 110,
        },
        {
          prop: "created_at",
          label: "创建时间",
          sortable: "custom",
          showOverflowTooltip: true,
          minWidth: 110,
        },
        {
          columnKey: "action",
          label: "操作",
          width: 230,
          align: "center",
          resizable: false,
          slot: "action",
        },
      ],
      // 表格选中数据
      selection: [],
      // 当前编辑数据
      current: null,
      // 是否显示编辑弹窗
      showEdit: false,
      // 是否显示导入弹窗
      showAuth: false,
    };
  },
  methods: {
    /* 刷新表格 */
    reload() {
      this.$refs.table.reload();
    },

    /* 显示分配权限 */
    openAuth(row) {
      this.current = row;
      this.showAuth = true;
    },
    /* 删除 */

    /* 批量删除 */

  },
};
</script>

<style scoped>
</style>
